import { useState, useEffect, useMemo } from 'react';

import { ConnectwiseHostedApi } from './connectwise-hosted-api';
import { ManageIntegration } from './manage-integration';
import { getEnvironmentType } from './selectors';

export function useManage() {
  const [tokenState, setTokenState] = useState('');
  const [ticketState, setTicketState] = useState({});
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [sessionData, setSessionData] = useState({});

  const hostedApiInstance = useMemo(
    () => new ConnectwiseHostedApi('*', [], getEnvironmentType() === 'qa'),
    []
  );
  const manageIntegrationInstance = useMemo(
    () =>
      new ManageIntegration(hostedApiInstance, getEnvironmentType() === 'qa'),
    [hostedApiInstance]
  );

  useEffect(() => {
    manageIntegrationInstance.on('connect', ({ token, ticket, user }) => {
      setTokenState(token);
      setTicketState(ticket);
      setSessionData(user);
      document.cookie = `SSO-Token=${token}; SameSite=None; Secure`;
    });

    manageIntegrationInstance.on('token-refresh', ({ token }) => {
      document.cookie = `SSO-Token=${token}; SameSite=None; Secure`;
      window.dispatchEvent(
        new CustomEvent('token-refresh', { detail: { token } })
      );
    });

    manageIntegrationInstance.on('token-expired', () => {
      setIsSessionExpired(true);
      window.dispatchEvent(new CustomEvent('token-expired'));
    });

    const disconnect = manageIntegrationInstance.connect();

    return () => {
      disconnect();
    };
  }, [manageIntegrationInstance]);

  return [
    { token: tokenState, ticket: ticketState, isSessionExpired, sessionData },
  ];
}
